import React from "react";
import ProductionsContent from "../ProductionsContent";
import Footer from "../Footer";

function Productions() {
  return (
    <>
      <ProductionsContent />
      <br></br>
      <Footer />
    </>
  );
}

export default Productions;
