import React from "react";
import Gallery from "./Gallery";

function HomePageContent() {
  return (
    <>
      <div style={{ backgroundColor: "rgba(128, 128, 128, 0.8)" }}>
        <br />
        <Gallery />
      </div>
    </>
  );
}

export default HomePageContent;
