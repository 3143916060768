import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./EmailSender.css";

const EmailSender = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    reply_to: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_3azxo0i", // Replace with your service ID
        "template_rsloqet", // Replace with your template ID
        formData,
        "0Ga6tivzivqmZuTDC" // Replace with your user ID
      )
      .then(
        (response) => {
          setStatus("Email sent successfully!");
          setFormData({ from_name: "", reply_to: "", message: "" }); // Reset form
        },
        (error) => {
          setStatus("Failed to send email. Please try again.");
        }
      );
  };

  return (
    <div>
      <form onSubmit={sendEmail}>
        <input
          type="text"
          name="from_name"
          placeholder="Your Name"
          value={formData.from_name}
          onChange={handleChange}
          required
        />
        <br></br>
        <input
          type="email"
          name="reply_to"
          placeholder="Your Email"
          value={formData.reply_to}
          onChange={handleChange}
          required
        />
        <br></br>
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <br></br>
        <button type="submit">Send Email</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

export default EmailSender;
