import React from "react";
import AboutContent from "../AboutContent";
import Footer from "../Footer";

function About() {
  return (
    <>
      <AboutContent />
      <br></br>
      <Footer />
    </>
  );
}

export default About;
