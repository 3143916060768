import React from "react";
import "./AboutContent.css";
import EmailSender from "./EmailSender";

function AboutContent() {
  return (
    <>
      <section className="hero">
        <div className="heading">
          <h1>Don't Quit Productions</h1>
        </div>
      </section>
      <br></br>
      <div className="container">
        <div className="hero-content">
          <h2>About Us</h2>
          <p>
            Our Mission: At Don’t Quit Productions (DQP), our mission is to
            empower independent artists by providing them with opportunities to
            showcase their talent on platforms not typically accessible. Whether
            it's improv, sketch, or film - we believe everyone can be funny and
            create something spectacular. Our goal is to discover and nurture
            this talent, helping it shine.
          </p>
        </div>
        <div className="hero-image">
          <img src="images/logo.png" className="headshot" alt="DQP" />
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <div className="hero-content">
          <h2>Want to pitch a show?</h2>
          <p>
            Whether you have an entire show ready for the stage, or just the
            seed of an idea, we would love to hear from you!
          </p>
          <EmailSender />
          {/* <button type="button" class="btn btn-outline-dark btn-rounded">
            <a
              href="mailto:ryantcoleman2020@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Email Us!
            </a>
          </button> */}
        </div>
      </div>
    </>
  );
}

export default AboutContent;
