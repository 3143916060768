import React from "react";
import CrewContent from "../CrewContent";
import Footer from "../Footer";

function MeetTheCrew() {
  return (
    <>
      <CrewContent />
      <br></br>
      <Footer />
    </>
  );
}

export default MeetTheCrew;
